exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-us-leadership-and-teams-js": () => import("./../../../src/pages/about-us/leadership-and-teams.js" /* webpackChunkName: "component---src-pages-about-us-leadership-and-teams-js" */),
  "component---src-pages-advanced-materials-js": () => import("./../../../src/pages/advanced-materials.js" /* webpackChunkName: "component---src-pages-advanced-materials-js" */),
  "component---src-pages-applications-js": () => import("./../../../src/pages/applications.js" /* webpackChunkName: "component---src-pages-applications-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrated-technologies-data-transfer-js": () => import("./../../../src/pages/integrated-technologies/data-transfer.js" /* webpackChunkName: "component---src-pages-integrated-technologies-data-transfer-js" */),
  "component---src-pages-integrated-technologies-feedback-js": () => import("./../../../src/pages/integrated-technologies/feedback.js" /* webpackChunkName: "component---src-pages-integrated-technologies-feedback-js" */),
  "component---src-pages-integrated-technologies-flexible-battery-js": () => import("./../../../src/pages/integrated-technologies/flexible-battery.js" /* webpackChunkName: "component---src-pages-integrated-technologies-flexible-battery-js" */),
  "component---src-pages-integrated-technologies-js": () => import("./../../../src/pages/integrated-technologies.js" /* webpackChunkName: "component---src-pages-integrated-technologies-js" */),
  "component---src-pages-integrated-technologies-sensing-js": () => import("./../../../src/pages/integrated-technologies/sensing.js" /* webpackChunkName: "component---src-pages-integrated-technologies-sensing-js" */),
  "component---src-pages-integrated-technologies-textile-circuitry-js": () => import("./../../../src/pages/integrated-technologies/textile-circuitry.js" /* webpackChunkName: "component---src-pages-integrated-technologies-textile-circuitry-js" */),
  "component---src-pages-integrated-technologies-thermal-regulation-js": () => import("./../../../src/pages/integrated-technologies/thermal-regulation.js" /* webpackChunkName: "component---src-pages-integrated-technologies-thermal-regulation-js" */),
  "component---src-pages-integrated-technologies-touch-textile-js": () => import("./../../../src/pages/integrated-technologies/touch-textile.js" /* webpackChunkName: "component---src-pages-integrated-technologies-touch-textile-js" */),
  "component---src-pages-integrated-technologies-visibility-js": () => import("./../../../src/pages/integrated-technologies/visibility.js" /* webpackChunkName: "component---src-pages-integrated-technologies-visibility-js" */),
  "component---src-pages-news-evolved-from-a-futuristic-accessory-to-a-ubiquitous-health-wellness-wearable-js": () => import("./../../../src/pages/news/evolved-from-a-futuristic-accessory-to-a-ubiquitous-health-&-wellness-wearable.js" /* webpackChunkName: "component---src-pages-news-evolved-from-a-futuristic-accessory-to-a-ubiquitous-health-wellness-wearable-js" */),
  "component---src-pages-news-game-changer-for-ar-and-vr-js": () => import("./../../../src/pages/news/game-changer-for-AR-and-VR.js" /* webpackChunkName: "component---src-pages-news-game-changer-for-ar-and-vr-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-mfd-for-electronically-enhanced-soft-goods-js": () => import("./../../../src/pages/news/mfd-for-electronically-enhanced-soft-goods.js" /* webpackChunkName: "component---src-pages-news-mfd-for-electronically-enhanced-soft-goods-js" */),
  "component---src-pages-news-reinventing-devices-for-today-and-tomorrow-js": () => import("./../../../src/pages/news/reinventing-devices-for-today-and-tomorrow.js" /* webpackChunkName: "component---src-pages-news-reinventing-devices-for-today-and-tomorrow-js" */),
  "component---src-pages-news-tomorrows-challenges-demand-new-approaches-js": () => import("./../../../src/pages/news/tomorrows-challenges-demand-new-approaches.js" /* webpackChunkName: "component---src-pages-news-tomorrows-challenges-demand-new-approaches-js" */),
  "component---src-pages-news-unlocking-the-potential-of-the-metaverse-js": () => import("./../../../src/pages/news/unlocking-the-potential-of-the-metaverse.js" /* webpackChunkName: "component---src-pages-news-unlocking-the-potential-of-the-metaverse-js" */),
  "component---src-pages-platforms-js": () => import("./../../../src/pages/platforms.js" /* webpackChunkName: "component---src-pages-platforms-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-textile-engineering-engineered-knit-js": () => import("./../../../src/pages/textile-engineering/engineered-knit.js" /* webpackChunkName: "component---src-pages-textile-engineering-engineered-knit-js" */),
  "component---src-pages-textile-engineering-injection-molding-js": () => import("./../../../src/pages/textile-engineering/injection-molding.js" /* webpackChunkName: "component---src-pages-textile-engineering-injection-molding-js" */),
  "component---src-pages-textile-engineering-js": () => import("./../../../src/pages/textile-engineering.js" /* webpackChunkName: "component---src-pages-textile-engineering-js" */),
  "component---src-pages-textile-engineering-narrow-width-fabrics-js": () => import("./../../../src/pages/textile-engineering/narrow-width-fabrics.js" /* webpackChunkName: "component---src-pages-textile-engineering-narrow-width-fabrics-js" */),
  "component---src-pages-toolkit-js": () => import("./../../../src/pages/toolkit.js" /* webpackChunkName: "component---src-pages-toolkit-js" */),
  "component---src-pages-toolkit-privacy-policy-js": () => import("./../../../src/pages/toolkit/privacy-policy.js" /* webpackChunkName: "component---src-pages-toolkit-privacy-policy-js" */),
  "component---src-pages-toolkit-return-policy-js": () => import("./../../../src/pages/toolkit/return-policy.js" /* webpackChunkName: "component---src-pages-toolkit-return-policy-js" */),
  "component---src-pages-toolkit-shipping-policy-js": () => import("./../../../src/pages/toolkit/shipping-policy.js" /* webpackChunkName: "component---src-pages-toolkit-shipping-policy-js" */),
  "component---src-pages-toolkit-terms-of-use-js": () => import("./../../../src/pages/toolkit/terms-of-use.js" /* webpackChunkName: "component---src-pages-toolkit-terms-of-use-js" */),
  "component---src-template-shop-js": () => import("./../../../src/template/shop.js" /* webpackChunkName: "component---src-template-shop-js" */)
}

